//
//
//
//
//
//

export default {
  name: 'DateComponent',
  data() {
    return {};
  },
  props: ['date'],
  components: {},
  methods: {},
  watch: {},
  computed: {},
  mounted() {},
};
